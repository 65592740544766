<template>
  <v-dialog
    value="loadingCreateRegistrant"
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="rounded ma-2"
        dark
        tile
        v-bind="attrs"
        color="danger"
        v-on="on"
        @click="getStates"
      >
        <v-icon>mdi-plus</v-icon>
        Add Registrant
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">New Registrant</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="registrationFrom" v-model="valid" lazy-validation>
          <v-stepper v-model="step" elevation="0">
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-text-field
                  v-model="newUser.email"
                  label="Email"
                  required
                  :rules="emailRules"
                />

                <v-text-field
                  v-model="newUser.password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  name="input-10-2"
                  label="Password"
                  :rules="passwordRules"
                  required
                  @click:append="show = !show"
                />
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  name="input-10-2"
                  label="Confirm Password"
                  :rules="passwordRules"
                  required
                  @click:append="show = !show"
                />
                <v-checkbox
                  v-model="newUser.accepts_terms"
                  :rules="requiredRules"
                  :value="true"
                  label="I have read and agree to the terms of service."
                />
                <v-btn
                  class="mb-4"
                  block
                  color="primary"
                  dark
                  @click="step = 2"
                >
                  Next
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="2">
                {{ newImage }}
                <v-text-field
                  v-model="newUser.contact.phone"
                  class="mt-4"
                  label="Mobile Or Phone Number"
                  :rules="requiredRules"
                  type="number"
                />
                <div>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :rules="requiredRules"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="newUser.contact.dob"
                        label="Date of Birth"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="newUser.contact.dob"
                      label="Date of Birth"
                      :active-picker.sync="activePicker"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1930-01-01"
                      @change="save"
                    />
                  </v-menu>
                </div>
                <v-text-field
                  v-model="newUser.contact.givenName"
                  label="Legal Firstname"
                  :rules="requiredRules"
                  required
                />
                <v-text-field
                  v-model="newUser.contact.surname"
                  label="Legal Surname"
                  :rules="requiredRules"
                  required
                />
                <v-file-input
                  v-model="newImage"
                  show-size
                  counter
                  multiple
                  required
                  label="Attach a headshot"
                />
                <v-btn block class="mr-4 mb-2 elevation-0" @click="step = 1">
                  Back
                </v-btn>
                <v-btn color="info" block class="mr-4" submit @click="step = 3">
                  next
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-text-field
                  v-model="newUser.location.address_line_1"
                  label="Address Line 1"
                  :rules="requiredRules"
                  required
                />
                <v-text-field
                  v-model="newUser.location.address_line_2"
                  label="Address Line 2"
                  required
                />
                <v-text-field
                  v-model="newUser.location.suburb"
                  label="Suburb"
                  :rules="requiredRules"
                  required
                />
                <v-select
                  v-model="newUser.location.state"
                  :items="states"
                  item-text="name"
                  item-value="_id"
                  label="State"
                  :rules="requiredRules"
                  :disabled="disableStates"
                  required
                />
                <v-text-field
                  v-model="newUser.location.postcode"
                  label="Postcode"
                  type="number"
                  :rules="requiredRules"
                  required
                />
                <v-checkbox
                  v-model="newUser.location.isInternational"
                  :false-value="false"
                  :true-value="true"
                  label="I am an international registrant."
                />
                <v-btn block class="mr-4 mb-2 elevation-0" @click="step = 2">
                  Back
                </v-btn>
                <v-btn color="info" block class="mr-4" submit @click="step = 4">
                  next
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="4">
                <template v-if="!loading">
                  <v-checkbox
                    v-model="consentCheckbox"
                    required
                    label="While a financial member of this SCCA State/Territory Association, I hereby agree to submit myself to a breath, blood, urine or saliva test at any time at the request of the Controlling Body governing my division of speedway racing.
I understand that if required to undergo a breath, blood, urine or saliva test it is for the purpose of determining whether I have consumed any substance prohibited by the World Anti Doping Authority and by the Controlling Body governing my division of speedway racing.
I understand and acknowledge that if I refuse to undergo a test when called upon to do so or substitute/tamper with a sample, then I am deemed to have consumed a prohibited substance and will be subject to such penalty/penalties as are prescribed by the Controlling Body.
I agree that it is my responsibility to make myself aware of the Rules, Regulations and Policies relating to both alcohol and drugs as set by the Controlling Body governing my division of speedway racing.
"
                  />
                  <v-btn block class="mr-4 mb-2 elevation-0" @click="step = 3">
                    Back
                  </v-btn>
                  <v-btn
                    color="info"
                    block
                    class="mr-4"
                    submit
                    :disabled="
                      !valid || !consentCheckbox || loadingCreateRegistrant
                    "
                    @click="submitRegistration"
                  >
                    Save
                  </v-btn>
                </template>
                <template v-if="loadingCreateRegistrant">
                  <v-progress-linear indeterminate color="primary" />
                </template>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="resetForm"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Register",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Registrant Registrantion",
  },
  data() {
    return {
      newImage: null,
      states: [],
      disableStates: false,
      newUser: {
        role: "Registrant",
        contact: {},
        location: {
          state: "",
          country: "Australia",
        },
      },
      step: 1,
      show: false,
      dialog: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      phoneNumberRules: [(v) => !!v || "Mobile/Phone number is required"],
      requiredRules: [(v) => !!v || "This field is required"],
      confirmPassword: "",
      checkbox1: true,
      checkbox2: false,
      consentCheckbox: false,
      activePicker: null,
      date: null,
      menu: false,
      valid: false,
      timeout: 2000,
      snackbar: false,
      text: "Task Name Must Be Required...",
    };
  },
  computed: {
    ...mapGetters(["error", "loading", "loadingCreateRegistrant"]),
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    loadingCreateRegistrant(val) {
      if (val === false) {
        this.resetForm();
      }
    },
    consentCheckbox(val) {
      if (val === true) {
        this.$refs.registrationFrom.validate();
      }
    },
  },
  methods: {
    ...mapActions(["register"]),
    save(date) {
      this.$refs.menu.save(date);
    },
    getStates() {
      this.$store.dispatch("getActiveStatesByRole").then((result) => {
        this.states = result.data;
        if (result.data.length === 1) {
          this.newUser.location.state = result.data[0]._id;
          this.disableStates = true;
        } else {
          this.newUser.location.state = "";
        }
      });
    },
    resetForm() {
      this.dialog = false;
      this.step = 1;
      this.$refs.registrationFrom.reset();
      Object.assign(this.$data, this.$options.data.call(this));
    },
    submitRegistration() {
      if (this.$refs.registrationFrom.validate()) {
        // preferred name added here
        this.newUser.contact.preferredName = `${this.newUser.contact.givenName} ${this.newUser.contact.surname}`;
        this.$store.dispatch("createRegistrant", {
          registrantDetails: this.newUser,
          role: "Registrant",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.apps-wrapper {
  .nested-sidebar-toggle {
    @media (min-width: 959px) {
      display: none;
    }
  }
}
.app-content-wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 200px);

  .app-overlay {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: none;
    z-index: 2;
    transition: all 0.3s ease-in;
    @media only screen and (max-width: 959px) {
      &.open {
        display: block;
        transition: all 0.3s ease-in;
      }
    }
  }
  .app-sidebar {
    width: 280px;
    position: relative;
    transition: all 0.3s ease-in;
    @media only screen and (max-width: 959px) {
      position: absolute;
      left: -340px;
      z-index: 5;
      height: calc(100vh - 120px);
      transition: all 0.3s ease-in;
      &.open {
        left: 0;
      }
    }
    .app-sidebar-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    .app-sidebar-body {
      // overflow-y: scroll;
      // height: 100%;
      padding-top: 3.375rem;
      width: 280px;
    }
  }

  .app-content {
    width: calc(100% - 280px);
    @media only screen and (max-width: 959px) {
      width: 100%;
    }
    // margin-left: 30px;
    .app-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    .app-body {
      flex: 1 1 auto;
      padding-top: 3.375rem;
      // overflow-y: scroll;
      height: calc(100% - 130px);
    }
    .app-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 130px;
    }
  }
}
.app-icon {
  display: none;
  @media only screen and (max-width: 959px) {
    display: block;
  }
}
.eg-filemanager {
  background-color: #fff;
}
.filemanager-content {
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
  &.--filemanager {
    .nested-sidebar-toggle {
      @media (min-width: 959px) {
        display: none;
      }
    }
  }
}

.page-wrap {
  background-color: #1e1e1e !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.theme--dark.v-stepper {
  background: #1e1e1e;
}
</style>
